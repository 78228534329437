angular
    .module('annexaApp')
    .component('annexaSendToSign', {
        templateUrl: './components/sign/annexa-send-to-sign/annexa-send-to-sign.html',
        controller:['Language', '$rootScope', 'SignLocalFactory', 'DialogsFactory', 'GlobalDataFactory', 'CacheFactory', 'ErrorFactory', '$filter', 'SignModals', 'AnnexaFormlyFactory', 'HelperService', 'RestService', '$q', 
        	function (Language, $rootScope, SignLocalFactory, DialogsFactory, GlobalDataFactory, CacheFactory, ErrorFactory, $filter, SignModals, AnnexaFormlyFactory, HelperService, RestService, $q) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.showRadioCircuitType = true;
            vm.presetCircuits = [];
            vm.signActionTypes = [];
            vm.actualCicuitType = 'PRESET';
            vm.esetMode = $rootScope.esetMode;
            vm.editCircuit = true;
            vm.listProfiles = [];

            vm.result = {
                observations: '',
                urgentReason: '',
                urgent: false,
                circuit: {
                    type: 'PRESET',
                    selected: undefined,
                    custom: [],
                    addToFavorite: false,
                    favoriteName: '',
                    profiles: []
                }
            };

            vm.checkRequired = function($data, column) {
                if(column.required && !$data) {
                    return $filter('translate')('global.validation.required');
                }
            };

            var printActionType = function(item) {
                if(!item.signActionType || item.signActionType == -1) {
                    return 'global.documents.modal.sendToSign.empty';
                }

                var indexColumnSignActionTypes = $linq(vm.columnsCustomCircuit).indexOf("x => x.id == 'signActionType'");

                if(indexColumnSignActionTypes != -1) {
                    var selected = $linq(vm.columnsCustomCircuit[indexColumnSignActionTypes].list).singleOrDefault(undefined, "x => x.id == " + item.signActionType);

                    if(selected) {
                        return selected[vm.languageColumn];
                    }
                }

                return 'global.documents.modal.sendToSign.empty';
            };
            
            vm.printSignActionType = function(signActionType, paralelConditionedAction) {
            	if(!paralelConditionedAction) {
            		return signActionType[vm.languageColumn];
            	} else {
            		return $filter('translate')('global.documents.modal.sendToSign.paralelConditionedSign');
            	}
            };

            var setReceiverType = function (value) {
            	var receiverType = '';
            	
            	if(value.interested) {
            		receiverType = $filter('translate')('global.literals.interested');
            	} else if(value.representated) {
            		var representated = '';

            		if(value.representated.corporateName) {
            			representated = '(' + value.representated.corporateName;
            		} else {
            			representated = '(' + value.representated.name + (value.representated.surname1Particles ? ' ' + value.representated.surname1Particles : '') + ' ' + value.representated.surename1 + (value.representated.surname2Particles ? ' ' + value.representated.surname2Particles : '') + ' ' + (value.representated.surename2 ? ' ' + value.representated.surename2 : '');
            		}

            		representated += '(' + value.representated.identificationDocument + '))';

            		receiverType = $filter('translate')('global.literals.representative', { representated: representated });
            	} else {
            		receiverType = $filter('translate')('global.literals.representative', { representated: '' });
            	}

            	return receiverType;
            };

            vm.addCustomCircuit = function() {
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { maxDurationDays: 5, signatureWithoutOrder:((vm.result.circuit.signatureWithoutOrder)?true:false) };
                var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                var conditionedActionListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                if(diliStampTypeField) {
                	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                		if(value.certificateType == 'ORGAN') {
                			value.label = value[Language.getActiveColumn()];
	           				diliStampTypeField.templateOptions.options.push(value);
                		}
           			});
                }
                
                var selectReceiver = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'selectReceiver'");
                if (selectReceiver) {
                	selectReceiver.templateOptions.options = [];
                    _.forEach(vm.dossierReceivers, function(value) {
                    	var receiverType = setReceiverType(value);
                    	
                    	if (value.third && value.third.addresses) {
                    		_.forEach(value.third.addresses, function(address) {
                    			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                    				selectReceiver.templateOptions.options.push({
                                		value: address.id,
                                		label: value.third.thirdType == 'PHISICAL' ? (value.third.name + (value.third.surname1Particles ? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + (value.third.surname2Particles ? ' ' + value.third.surname2Particles : '') + ' ' + (value.third.surename2 ? ' ' + value.third.surename2 : '')) : value.third.corporateName,
                                		labelHtml: receiverType,
                                		third: value.third,
                                		address: address,
                                		emailHtml: address.telematicValue
                                	});
                    			}
                    		});
                    	}
                    });
                    
                    if (vm.documents) {
                    	_.forEach(vm.documents, function(doc) {
                    		if (doc.thirds) {
                    			_.forEach(doc.thirds, function(third) {
                                	if (third.addresses) {
                                		_.forEach(third.addresses, function(address) {
                                			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                                				selectReceiver.templateOptions.options.push({
                                            		value: address.id,
                                            		label: third.thirdType == 'PHISICAL' ? (third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1 + (third.surname2Particles ? ' ' + third.surname2Particles : '') + ' ' + (third.surename2 ? ' ' + third.surename2 : '')) : third.corporateName,
//                                            		labelHtml: receiverType,
                                            		third: third,
                                            		address: address,
                                            		emailHtml: address.telematicValue
                                            	});
                                			}
                                		});
                                	}
                    			});
                    		}
                    		if (doc.dossierTransactionDocuments) {
                    			_.forEach(doc.dossierTransactionDocuments, function(dtd) {
                                	if (dtd.dossierTransaction && dtd.dossierTransaction.dossier && dtd.dossierTransaction.dossier.thirds) {
                                		_.forEach(dtd.dossierTransaction.dossier.thirds, function(value) {
                                			var receiverType = setReceiverType(value);
                                        	
                                        	if (value.third && value.third.addresses) {
                                        		_.forEach(value.third.addresses, function(address) {
                                        			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                                        				selectReceiver.templateOptions.options.push({
                                                    		value: address.id,
                                                    		label: value.third.thirdType == 'PHISICAL' ? (value.third.name + (value.third.surname1Particles ? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + (value.third.surname2Particles ? ' ' + value.third.surname2Particles : '') + ' ' + (value.third.surename2 ? ' ' + value.third.surename2 : '')) : value.third.corporateName,
                                                    		labelHtml: receiverType,
                                                    		third: value.third,
                                                    		address: address,
                                                    		emailHtml: address.telematicValue
                                                    	});
                                        			}
                                        		});
                                        	}
                                		});
                                	}
                    			});
                    		}
                    	});
                    }
                    
                	if (selectReceiver.templateOptions.options) {
                		modal.annexaFormly.model.modal_body.receiver = 'related';
                	}
                }
                if(conditionedActionListField) {
                	conditionedActionListField.data.actionsList = [];
                	conditionedActionListField.data.chkSignatoryType = false;
                	conditionedActionListField.data.selectReceiverList = selectReceiver.templateOptions.options;
                }

            	var add = function() {
            		var model = this.annexaFormly.model.modal_body;
            		var valid = true;
            		if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'THIRDSIGN') {
            			model.onlyOneSign = false;
            			model.user = undefined;
            			model.diligenceStampType = undefined;
            			model.subject = undefined;
            			model.subjectAux = undefined;
            			model.paralelSignAction = [];
            			if (model.receiver == 'others') {
            				model.selectReceiver = undefined;
            			}
            			if (model.selectReceiver) {
            				var receiver = $linq(selectReceiver.templateOptions.options).firstOrDefault(undefined, "x => x.value == " + model.selectReceiver);
            				model.third = receiver.third;
            				model.third.value = receiver.label;
            				model.thirdAddress = receiver.address;
            			}
            		} else if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'AUTOSIGN_ORGAN') {
            			model.onlyOneSign = false;
            			model.user = undefined ;
            			model.third = undefined ;
            			model.thirdAddress = undefined;
            			model.paralelSignAction = [];
            			model.receiver = undefined;
            		} else if(model && model.paralelConditionedAction) {
            			if(this.annexaFormly.fields && this.annexaFormly.fields[0] && this.annexaFormly.fields[0].fieldGroup) {
            				var conditionedActionListField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
            				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.actionsList) {
            					if(conditionedActionListField.data.actionsList.length > 0) {
            						model.signActionType = undefined;
            						model.user = undefined;
                        			model.signatoryType = undefined;
                        			model.third = undefined;
                        			model.thirdAddress = undefined;
                        			model.diligenceStampType = undefined;
                        			model.subject = undefined;
                        			model.subjectAux = undefined;
                        			model.paralelSignAction = conditionedActionListField.data.actionsList;
                        			model.receiver = undefined;
            					} else {
            						valid = false;
            						this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorConditionedSignNoAction' });
            					}
            				}
            			}
            		} else {
            			model.onlyOneSign = false;
            			model.third = undefined;
            			model.thirdAddress = undefined;
            			model.diligenceStampType = undefined;
            			model.subject = undefined;
            			model.subjectAux = undefined;
            			model.paralelSignAction = [];
            			model.receiver = undefined;
            		}
            		if(valid) {
            			vm.result.circuit.custom.push({
                			paralelConditionedAction: model.paralelConditionedAction,
                			actionOrder: vm.result.circuit.custom.length + 1,
                            user: model.user,
                        	receiver: model.receiver,
                        	selectReceiver: model.selectReceiver,
                            third: model.third,
                            signActionType: model.signActionType,
                            maxDurationDays: model.maxDurationDays,
                            thirdAddress: model.thirdAddress,
                            diligenceStampType: model.diligenceStampType,
                            subject: model.subject,
                            subjectAux: model.subjectAux,
                            paralelSignAction: model.paralelSignAction,
                            onlyOneSign: model.onlyOneSign
                        });
                		this.close();
            		}
            	};
            	AnnexaFormlyFactory.showModal("modalAddCustomCircuit", modal, add, false);
            };
            
            vm.editCustomCircuit = function($index) {
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = angular.copy(vm.result.circuit.custom[$index]);
				modal.annexaFormly.model.modal_body.signatureWithoutOrder = ((vm.result.circuit.signatureWithoutOrder)?true:false);
				if (modal.annexaFormly.model.modal_body.selectReceiver) {
					modal.annexaFormly.model.modal_body.third = undefined;
					modal.annexaFormly.model.modal_body.thirdAddress = undefined;
				}
            	modal.title = 'global.documents.modal.sendToSign.editUserThird';
            	var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                var conditionedActionListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                if(diliStampTypeField) {
                	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                		if(value.certificateType == 'ORGAN') {
                			value.label = value[Language.getActiveColumn()];
               				diliStampTypeField.templateOptions.options.push(value);
               				if(vm.result.circuit.custom[$index].diligenceStampType && vm.result.circuit.custom[$index].diligenceStampType.id == value.id) {
               					vm.result.circuit.custom[$index].diligenceStampType = value;
                            }
                		}
           			});
                }
                var selectReceiver = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'selectReceiver'");
                if (selectReceiver) {
                	selectReceiver.templateOptions.options = [];
                    _.forEach(vm.dossierReceivers, function(value) {
                    	var receiverType = setReceiverType(value);
                    	
                    	if (value.third && value.third.addresses) {
                    		_.forEach(value.third.addresses, function(address) {
                    			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                    				selectReceiver.templateOptions.options.push({
                                		value: address.id,
                                		label: value.third.thirdType == 'PHISICAL' ? (value.third.name + (value.third.surname1Particles ? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + (value.third.surname2Particles ? ' ' + value.third.surname2Particles : '') + ' ' + (value.third.surename2 ? ' ' + value.third.surename2 : '')) : value.third.corporateName,
                                		labelHtml: receiverType,
                                		third: value.third,
                                		address: address,
                                		emailHtml: address.telematicValue
                                	});
                    			}
                    		});
                    	}
                    });
                    
                    if (vm.documents) {
                    	_.forEach(vm.documents, function(doc) {
                    		if (doc.thirds) {
                    			_.forEach(doc.thirds, function(third) {
                                	if (third.addresses) {
                                		_.forEach(third.addresses, function(address) {
                                			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                                				selectReceiver.templateOptions.options.push({
                                            		value: address.id,
                                            		label: third.thirdType == 'PHISICAL' ? (third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1 + (third.surname2Particles ? ' ' + third.surname2Particles : '') + ' ' + (third.surename2 ? ' ' + third.surename2 : '')) : third.corporateName,
//                                            		labelHtml: receiverType,
                                            		third: third,
                                            		address: address,
                                            		emailHtml: address.telematicValue
                                            	});
                                			}
                                		});
                                	}
                    			});
                    		}
                    		if (doc.dossierTransactionDocuments) {
                    			_.forEach(doc.dossierTransactionDocuments, function(dtd) {
                                	if (dtd.dossierTransaction && dtd.dossierTransaction.dossier && dtd.dossierTransaction.dossier.thirds) {
                                		_.forEach(dtd.dossierTransaction.dossier.thirds, function(value) {
                                			var receiverType = setReceiverType(value);
                                        	
                                        	if (value.third && value.third.addresses) {
                                        		_.forEach(value.third.addresses, function(address) {
                                        			if (address.addressType == 'TELEMATIC_EMAIL' && $linq(selectReceiver.templateOptions.options).count("x => x.value =='" + address.id + "'") == 0) {
                                        				selectReceiver.templateOptions.options.push({
                                                    		value: address.id,
                                                    		label: value.third.thirdType == 'PHISICAL' ? (value.third.name + (value.third.surname1Particles ? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + (value.third.surname2Particles ? ' ' + value.third.surname2Particles : '') + ' ' + (value.third.surename2 ? ' ' + value.third.surename2 : '')) : value.third.corporateName,
                                                    		labelHtml: receiverType,
                                                    		third: value.third,
                                                    		address: address,
                                                    		emailHtml: address.telematicValue
                                                    	});
                                        			}
                                        		});
                                        	}
                                		});
                                	}
                    			});
                    		}
                    	});
                    }
                    
                    if (selectReceiver.templateOptions.options && !modal.annexaFormly.model.modal_body.receiver) {
                		modal.annexaFormly.model.modal_body.receiver = 'related';
                	}
                }
                if(conditionedActionListField) {
                	conditionedActionListField.data.actionsList = vm.result.circuit.custom[$index].paralelSignAction;
                	conditionedActionListField.data.chkSignatoryType = false;
                	conditionedActionListField.data.selectReceiverList = selectReceiver.templateOptions.options;
                }

            	var add = function() {
            		var model = this.annexaFormly.model.modal_body;
            		var valid = true;
            		if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'THIRDSIGN') {
            			model.onlyOneSign = false;
            			model.user = undefined;
            			model.diligenceStampType = undefined;
            			model.subject = undefined;
            			model.subjectAux = undefined;
            			model.paralelSignAction = [];
            			if (model.receiver == 'others') {
            				model.selectReceiver = undefined;
            			}
            			if (model.selectReceiver) {
            				var receiver = $linq(selectReceiver.templateOptions.options).firstOrDefault(undefined, "x => x.value == " + model.selectReceiver);
            				model.third = receiver.third;
            				model.third.value = receiver.label;
            				model.thirdAddress = receiver.address;
            			}
            		} else if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'AUTOSIGN_ORGAN') {
            			model.onlyOneSign = false;
            			model.user = undefined;
            			model.third = undefined;
            			model.thirdAddress = undefined;
            			model.paralelSignAction = [];
            			model.receiver = undefined;
            			if(model.diligenceStampType.canChange) {
            				vm.result.circuit.custom[$index].customText = model.subject;
            			} else {
            				vm.result.circuit.custom[$index].customText = model.subjectAux;
            			}
            		} else if(model && model.paralelConditionedAction) {
            			if(this.annexaFormly.fields && this.annexaFormly.fields[0] && this.annexaFormly.fields[0].fieldGroup) {
            				var conditionedActionListField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
            				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.actionsList) {
            					if(conditionedActionListField.data.actionsList.length > 0) {
            						model.signActionType = undefined;
                        			model.user = undefined;
                        			model.third = undefined;
                        			model.thirdAddress = undefined;
                        			model.diligenceStampType = undefined;
                        			model.subject = undefined;
                        			model.subjectAux = undefined;
                        			model.paralelSignAction = conditionedActionListField.data.actionsList;
                        			model.receiver = undefined;
            					} else {
            						valid = false;
            						this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorConditionedSignNoAction' });
            					}
            				}
            				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.deleteActionsList 
            						&& conditionedActionListField.data.deleteActionsList.length > 0) {
            					_.forEach(conditionedActionListField.data.deleteActionsList, function (value) {
    	                            value.deleted = true;
    	                            value.removedDate = new Date();
    	                            model.paralelSignAction.push(value);
                    			});
            				}
            			}
            		} else {
            			model.onlyOneSign = false;
            			model.third = undefined;
            			model.thirdAddress = undefined;
            			model.diligenceStampType = undefined;
            			model.subject = undefined;
            			model.subjectAux = undefined;
            			model.paralelSignAction = [];
            			model.receiver = undefined;
            		}
            		if(valid) {
                		vm.result.circuit.custom[$index] = {
                    		paralelConditionedAction: model.paralelConditionedAction,
                            actionOrder: vm.result.circuit.custom[$index].actionOrder,
                            user: model.user,
                        	receiver: model.receiver,
                        	selectReceiver: model.selectReceiver,
                            third: model.third,
                            signActionType: model.signActionType,
                            maxDurationDays: model.maxDurationDays,
                            thirdAddress: model.thirdAddress,
                            diligenceStampType: model.diligenceStampType,
                            subject: model.subject,
                            subjectAux: model.subjectAux,
                            paralelSignAction: model.paralelSignAction,
                            onlyOneSign: model.onlyOneSign
                        };
                		this.close();
            		}
            	};
            	AnnexaFormlyFactory.showModal("modalAddCustomCircuit", modal, add, false);
            };

            vm.removeCustomCircuit = function($index) {
                for(var i = $index; i < vm.result.circuit.custom.length; i++) {
                    vm.result.circuit.custom[i].actionOrder = vm.result.circuit.custom[i].actionOrder -1;
                }

                vm.result.circuit.custom.splice($index, 1);
            };

            vm.columnsCustomCircuit = [
                { id: 'actionOrder', style: 'width:17%;', name: 'global.documents.modal.sendToSign.order', type: 'label', min: function () { return 1; }, max: function () { return vm.circuit.custom.length; }, required: true },
                { id: 'user', style: 'width:25%;', name: 'global.documents.modal.sendToSign.user', required: true, type: 'loadUser' },
                { id: 'signActionType', style: 'width:25%;', name: 'global.documents.modal.sendToSign.signType', required: true, type: 'select', nameProperty: vm.languageColumn, list: [], print: printActionType },
                { id: 'maxDurationDays', style: 'width:17%', name: 'global.documents.modal.sendToSign.days', type: 'number', min: function () { return 1; }, max: function () { return 365; }, required: true },
                { id: 'actions', style: 'width:16%', name: '', type: 'actions' }
            ];

            vm.changeCircuitType = function() {
                if(vm.actualCicuitType != vm.result.circuit.type) {
                    vm.actualCicuitType = vm.result.circuit.type;
                    vm.result.circuit.custom = [];
                    vm.result.circuit.selected = undefined;
                    vm.result.circuit.addToFavorite = false;
                    vm.result.circuit.favoriteName = '';
					vm.result.circuit.signatureWithoutOrder = false;
                    vm.result.circuit.edit = false;
                    vm.result.circuit.alertProfiles = false;
                    vm.result.circuit.profiles = [];
                    vm.isEditCircuit = false;
					if(vm.result.circuit.type == 'CUSTOM'){
						vm.showSignatureWithoutOrder = true;
					}else{
						vm.showSignatureWithoutOrder = false;
					}
                }
            };

            vm.customizePreset = function() {
                vm.actualCicuitType = 'CUSTOM';
                vm.result.circuit.type = 'CUSTOM';
                vm.result.circuit.custom = [];
				vm.result.circuit.signatureWithoutOrder = vm.result.circuit.selected.signatureWithoutOrder;
				vm.result.circuit.alertProfiles = vm.result.circuit.selected.sendAlertProfile;
				vm.result.circuit.profiles = [];
                vm.isEditCircuit = true;
				vm.showSignatureWithoutOrder = true;
				if(vm.result.circuit.alertProfiles) {
					_.forEach(vm.result.circuit.selected.presetSignCircuitAlertProfiles, function(item) {
						vm.result.circuit.profiles.push($linq(vm.listProfiles).first("x => x.id == " + item.profile.id));
					});
				}
                _.forEach($linq(vm.result.circuit.selected.presetSignActions).orderBy("x => x.actionOrder").toArray(), function(item) {
                	var user;
                	var third;
                	var thirdAddress;
                	if(item.user) {
                		user = {
                				id: item.user.id, 
                				user: {
                					id: item.user.id, 
                					name: item.user.name, 
                					surename1: item.user.surename1, 
                					surename2: item.user.surename2
                				}, 
                				value: item.user.name + ' ' + item.user.surename1 +(item.user.surename2 ? ' ' + item.user.surename2 : '')};
                	}
                	if(item.third) {
                        third = { 
                        		id:  item.third.id,
                        		third: {
                                		id: item.third.id,
                                		name: item.third.name,
                                		surname1Particles: item.third.surname1Particles,
                        				surename1: item.third.surename1, 
                                		surname2Particles: item.third.surname2Particles,
                        				surename2: item.third.surename2,
                        				corporateName: item.third.corporateName
                                }, 
                        		value: item.third.value 
                        }
                	}
                	if(item.thirdAddress) {
                		thirdAddress = {
                				id: item.thirdAddress.id,
                				telematicValue: item.thirdAddress.telematicValue
                		}
                	}
                    vm.result.circuit.custom.push({
                        actionOrder: item.actionOrder,
                        user: user,
                        signActionType: item.signActionType,
                        maxDurationDays: item.maxDurationDays,
                        third: third,
                        thirdAddress: thirdAddress,
                        diligenceStampType: item.diligenceStampType,
                        subject: item.subject,
                        subjectAux: item.subjectAux,
                        paralelConditionedAction: item.paralelConditionedAction,
                        paralelSignAction: item.paralelSignAction,
                        onlyOneSign: item.onlyOneSign
                    });
                });
            };
            
            vm.checkChange = function(check) {
            	vm.result.circuit.favoriteName = '';
            	if(check == 'edit') {
            		vm.result.circuit.addToFavorite = false;
            	} else if(check == 'favourite') {
            		vm.result.circuit.edit = false;
            	}
            }
            
            vm.deleteCustomCircuit = function() {
            	DialogsFactory.confirm('global.documents.modal.sendToSign.confirmDeleteCustomCircuitTitle', 'global.documents.modal.sendToSign.confirmDeleteCustomCircuitContent')
	                .then(function () {
	                    $rootScope.loading(true);
	                    SignLocalFactory.deleteSignCircuit(vm.result.circuit.selected.id)
	                    .then(function () {
	                            $rootScope.loading(false);
	                            if(!HelperService.isSuperAdmin()) {
	                            	var index = $linq($rootScope.LoggedUser.presetSignCircuits).indexOf("x => x.id == " + vm.result.circuit.selected.id);
		                            if (index > -1) {
		                            	$rootScope.LoggedUser.presetSignCircuits.splice(index, 1);
	                            	}
		                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('LoggedUserData')) {
		                            	var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
		                                cache.userObject.presetSignCircuits = $rootScope.LoggedUser.presetSignCircuits;
		                                CacheFactory.get('globalDataCache').remove('LoggedUserData');
		                                CacheFactory.get('globalDataCache').put('LoggedUserData', cache);
		                            }
		                            if(GlobalDataFactory.presetSignCircuit){
		                            	GlobalDataFactory.presetSignCircuit.length = 0;
		                            }else{
		                            	GlobalDataFactory.presetSignCircuit = [];
		                            }
		                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
		                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
		                            }
		                            GlobalDataFactory.loadGlobalData();
	                            }
	                            vm.actualCicuitType = vm.result.circuit.type;
	                            vm.result.circuit.custom = [];
	                            vm.result.circuit.selected = undefined;
	                            vm.result.circuit.addToFavorite = false;
	                            vm.result.circuit.favoriteName = '';
	                            vm.result.circuit.edit = false;
	                            vm.result.circuit.alertProfiles = false;
								vm.result.circuit.signatureWithoutOrder = false;
			                    vm.result.circuit.profiles = [];
	                            vm.isEditCircuit = false;
	                            loadPresetSignCircuits();
	                        }).catch(function (error) {
	                            $rootScope.loading(false);
	                            DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'checkDelete', error.data ), $filter('translate')('DIALOGS_ERROR_MSG'));
	                    });
	                });
            }

            vm.updateCustomCircuit = function($data) {
                //Empty
            };

            var loadPresetSignCircuits = function () {
                var getSignActionUserBySectionLevel = function (section, level, users) {
                    var user ;

                    if(section && level) {
                        var addUser = { id: undefined };

                        if(section.responsible) {
                            addUser = section.responsible;
                        }

                        if(section.parent) {
                            users.push(addUser);
                            user = getSignActionUserBySectionLevel(section.parent, level, users);
                        } else {
                            users.push(addUser);

                            if(users.length >= (level - 1)) {
                                user = users[level - 1];
                            }
                        }
                    }

                    if(user && !user.id) {
                        user = undefined;
                    }

                    return user;
                };

                var fixPresetSignCircuit = function (presetSignCircuit, section) {
                    var addCircuit = true;

                    _.forEach(presetSignCircuit.presetSignActions, function (psaItem) {
                        if(psaItem.signActionType && psaItem.signActionType.code == 'PARALELCONDITIONEDSIGN')  {
                        	_.forEach(psaItem.paralelSignAction, function (paralelActionItem) {
                        		if(paralelActionItem.signatoryType && paralelActionItem.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                        			paralelActionItem.user = getSignActionUserBySectionLevel(section, paralelActionItem.actionLevel, []);
                        			paralelActionItem.signatoryType = { id: $rootScope.app.configuration.signatory_type_user.value }
                                    if(!paralelActionItem.user) {
                                        addCircuit = false;
                                    }
                                }
                            });
                        } else if(psaItem.signatoryType && psaItem.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                            psaItem.user = getSignActionUserBySectionLevel(section, psaItem.actionLevel, []);

                            if(!psaItem.user) {
                                addCircuit = false;
                            }
                        }
                    });

                    return {
                        add: addCircuit,
                        circuit: presetSignCircuit
                    };
                };

                var getDocumentTypeCircuits = function(documentType, section, circuits) {
                    var documentTypeCircuits = [];

                    if(documentType.presetSignCircuits) {
                        _.forEach(documentType.presetSignCircuits, function (pscItem) {
                            if($linq(circuits).count("x => x.id == " + pscItem.id) == 0) {
                                var fixedCircuit = fixPresetSignCircuit(pscItem, section);

                                if (fixedCircuit.add) {
                                    documentTypeCircuits.push(fixedCircuit.circuit);
                                }
                            }
                        });
                    }

                    return documentTypeCircuits;
                };

                var getEditCircuit = function(document) {
                    if(isProposalDocument(document) || isTemplateBased(document)) {
                    	var edit = undefined;
                    	_.forEach(document.listPresetSignCircuits, function (psc) {
                    		if(typeof psc.editPresetSignCircuit !== 'undefined' && psc.editPresetSignCircuit != null) {
                    			if(!psc.editPresetSignCircuit) {
                    				edit = psc.editPresetSignCircuit;
                    				return edit;
                    			} else {
                    				edit = psc.editPresetSignCircuit;
                    			}
                    		}
                    	});
                    	if(typeof edit === 'undefined'){
                    		return document.type.editPresetSignCircuit;
                    	}
                        return edit;
                    } else {
                        return document.type.editPresetSignCircuit;
                    }
                };

                var getTemplateCircuits = function(document, section, circuits) {
                    var documentTemplateCircuits = [];

                    var templateProperty = getTemplateProperty(document);
                    
                    _.forEach(document[templateProperty].templateSignCircuit, function (tscItem) {
                        if($linq(circuits).count("x => x.id == " + tscItem.presetSignCircuit.id) == 0) {
                            var fixedCircuit = fixPresetSignCircuit(tscItem.presetSignCircuit, section);
                            
                            if(fixedCircuit.add) {
                                documentTemplateCircuits.push(fixedCircuit.circuit);
                            }
                        }
                    });
                    return documentTemplateCircuits;
                };

                vm.presetCircuits = [];

                _.forEach(vm.documents, function(dItem) {
                	var documentCacSign = false
                	if(!dItem.signProcesses){
                		documentCanSign = true;
                	}else{
                		var processSignPending = $linq(dItem.signProcesses).where("x=>x.signState == 'PENDING' || x.signState == 'IDLE'").toArray();
                		if(!processSignPending || processSignPending.length == 0){
                			documentCanSign = true;
                		}
                		
                	}
                    if(documentCanSign) {
                        var documentPresetCircuits = [];

                        if(isProposalDocument(dItem) || (isTemplateBased(dItem) && hasTemplateCircuits(dItem))) {
                        	var fixedCircuits = [];
                        	_.forEach(dItem.listPresetSignCircuits, function(dpsc) {
                        		fixedCircuits.push(fixPresetSignCircuit(dpsc, dItem.section));
                        	});
                        	documentPresetCircuits = $linq(fixedCircuits).select("x => x.circuit").toArray();
                        } else if((isTemplateBased(dItem) && !hasTemplateCircuits(dItem)) || !isTemplateBased(dItem)) {
                            documentPresetCircuits = getDocumentTypeCircuits(dItem.type, dItem.section, documentPresetCircuits);
                        }

                        if(vm.editCircuit) {
                        	vm.editCircuit = vm.editCircuit && getEditCircuit(dItem);
                        }

                        if(documentPresetCircuits.length > 0) {
                        	documentPresetCircuits = fixCircuit(documentPresetCircuits, false);
                        }
                        if(vm.presetCircuits.length == 0) {
                            vm.presetCircuits = documentPresetCircuits;
                        } else {
                            var distincs = $linq(documentPresetCircuits).except(vm.presetCircuits, "(x, y) => x.id == y.id").toArray();

                            vm.presetCircuits.push.apply(vm.presetCircuits, distincs);
                        }
                    }
                });
                vm.isTemplatePresetCircuits = vm.presetCircuits.length > 0;
                if(vm.editCircuit) {
                	if(HelperService.isSuperAdmin()) {
                		SignLocalFactory.getAllUsersPresetSignCircuits().then(function(data) {
                			getUserCircuits(JSOG.decode(data));
                        });
                	} else {
                		getUserCircuits($rootScope.LoggedUser.presetSignCircuits);
                	}
                    
                }


            };
            
            var isTemplateBased = function(document) {
                if(document.template || document.tramTemplate || document.secTemplate) {
                    return true;
                }

                return false;
            };
            
            var isProposalDocument = function(document) {
        		var isPropDoc = false;
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_document_types) {
            		if(!isPropDoc && $rootScope.app.configuration.sec_document_types.SPD && $rootScope.app.configuration.sec_document_types.SPD.id
            				&& $rootScope.app.configuration.sec_document_types.SPD.id.length > 0) {
            			isPropDoc = $linq($rootScope.app.configuration.sec_document_types.SPD.id).contains(document.type.id.toString());
            		}
            		if(!isPropDoc && $rootScope.app.configuration.sec_document_types.SPR && $rootScope.app.configuration.sec_document_types.SPR.id
            				&& $rootScope.app.configuration.sec_document_types.SPR.id.length > 0) {
            			isPropDoc = $linq($rootScope.app.configuration.sec_document_types.SPR.id).contains(document.type.id.toString());
            		}
            		if(!isPropDoc && $rootScope.app.configuration.sec_document_types.SPA && $rootScope.app.configuration.sec_document_types.SPA.id
            				&& $rootScope.app.configuration.sec_document_types.SPA.id.length > 0) {
            			isPropDoc = $linq($rootScope.app.configuration.sec_document_types.SPA.id).contains(document.type.id.toString());
            		}
            		return isPropDoc;
            	}
            	return isPropDoc;
            }

            var hasTemplateCircuits = function(document) {
                var templateProperty = getTemplateProperty(document);

                if(templateProperty) {
                    return document[templateProperty].templateSignCircuit && document[templateProperty].templateSignCircuit.length > 0;
                }

                return false;
            };

            var getTemplateProperty = function (document) {
                if(document.template) {
                    return 'template';
                }

                if(document.tramTemplate) {
                    return 'tramTemplate';
                }

                if(document.secTemplate) {
                    return 'secTemplate';
                }
            };
            
            var getUserCircuits = function(signCircuits) {
                var userCircuits = fixCircuit(signCircuits, true);
                vm.presetCircuits.push.apply(vm.presetCircuits, userCircuits);
            }
            
            var fixCircuit = function(circuits, isUserCircuit) {
            	return $linq(circuits).select(function(x) {
                    return {
                        id: x.id,
                        language1: x.language1,
                        language2: x.language2,
                        language3: x.language3,
						userCircuit: isUserCircuit,
						signatureWithoutOrder: x.signatureWithoutOrder,
						sendAlertProfile: x.sendAlertProfile,
						presetSignCircuitAlertProfiles: x.presetSignCircuitAlertProfiles,
                        presetSignActions: $linq(x.presetSignActions).select(function(y) {
                        	var user;
                        	var third;
                        	var thirdAddress;
                        	if(y.user && y.user.id && y.user.name && y.user.surename1) {
                        		user = { 
                        				id: y.user.id, 
                        				name: y.user.name, 
                        				surename1: y.user.surename1, 
                        				surename2: y.user.surename2 
                        		};
                        	} else if (y.relatedUser && y.relatedUser.id && y.relatedUser.name && y.relatedUser.surename1) {
                        		user = {
                        				id: y.relatedUser.id, 
                        				name: y.relatedUser.name, 
                        				surename1: y.relatedUser.surename1, 
                        				surename2: y.relatedUser.surename2 
                        		};
                        	}
                        	if(y.third && y.third.id && ((y.third.name && y.third.surename1) || (y.third.corporateName))) {
                        		var name = '';
                                if(y.third.corporateName) {
                                    name = y.third.corporateName;
                                } else {
                                    name = y.third.name + ((y.third.surname1Particles) ? ' ' + y.third.surname1Particles : '') + ' ' + y.third.surename1;
                                }

                                if(y.third.surename2) {
                                    name += ((y.third.surname2Particles) ? ' ' + y.third.surname2Particles : '') + ' ' + y.third.surename2;
                                }
                                third = {
                                		id: y.third.id,
                                		name: y.third.name,
                                		surname1Particles: y.third.surname1Particles,
                        				surename1: y.third.surename1,
                                		surname2Particles: y.third.surname2Particles,
                        				surename2: y.third.surename2,
                        				corporateName: y.third.corporateName,
                        				value: name
                                }
                        	}
                        	if(y.thirdAddress && y.thirdAddress.id && y.thirdAddress.telematicValue) {
                        		thirdAddress = {
                        				id: y.thirdAddress.id,
                        				telematicValue: y.thirdAddress.telematicValue
                        		}
                        	}
                        	if(y.paralelConditionedAction) {
                        		_.forEach(y.paralelSignAction, function(paralelAction) {
                        			if (!paralelAction.user && paralelAction.relatedUser && paralelAction.relatedUser.id && paralelAction.relatedUser.name 
                                			&& paralelAction.relatedUser.surename1) {
                                		paralelAction.user = {
                                				id: paralelAction.relatedUser.id, 
                                				name: paralelAction.relatedUser.name, 
                                				surename1: paralelAction.relatedUser.surename1, 
                                				surename2: paralelAction.relatedUser.surename2 ,
                                				completeName: paralelAction.relatedUser.completeName
                                		};
                                	}
                                	if(!paralelAction.signatoryType) {
                                		paralelAction.signatoryType = { id: $rootScope.app.configuration.signatory_type_user.value };
                                	}
                        		});
                        	}
                        	if(!y.signatoryType) {
                    			y.signatoryType = { id: $rootScope.app.configuration.signatory_type_user.value };
                        	}
                            return {
                                id: y.id,
                                actionLevel: y.actionLevel,
                                actionOrder: y.actionOrder,
                                maxDurationDays: y.maxDurationDays,
                                signActionType: y.signActionType,
                                signatoryType: y.signatoryType,
                                user: user,
                                third: third,
                                thirdAddress: thirdAddress,
                                diligenceStampType: y.diligenceStampType,
                                subject: y.customText,
                                subjectAux: y.customText,
                                paralelConditionedAction: y.paralelConditionedAction,
                                paralelSignAction: y.paralelSignAction,
                                onlyOneSign: y.onlyOneSign
                            };
                        }).toArray()
                    };
                }).toArray();
            }

            this.$onInit = function() {
				vm.showSignatureWithoutOrder = false;
				vm.listProfiles = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                if(vm.documents) {
	                var indexColumnSignActionTypes = $linq(vm.columnsCustomCircuit).indexOf("x => x.id == 'signActionType'");
	
	                if(indexColumnSignActionTypes != -1) {
	                    vm.columnsCustomCircuit[indexColumnSignActionTypes].list = GlobalDataFactory.signActionTypes;
	                }
	                var promises = [];
	                var docTemplSignCircuit = [];
	                _.forEach(vm.documents, function(dItem) {
	                	var documentCacSign = false
	                	if(!dItem.signProcesses){
	                		documentCanSign = true;
	                	}else{
	                		var processSignPending = $linq(dItem.signProcesses).where("x=>x.signState == 'PENDING' || x.signState == 'IDLE'").toArray();
	                		if(!processSignPending || processSignPending.length == 0){
	                			documentCanSign = true;
	                		}
	                		
	                	}
	                    if(documentCanSign) {
	                        if(isProposalDocument(dItem) || (isTemplateBased(dItem) && hasTemplateCircuits(dItem))) {
	                        	promises.push(RestService.findByUrl('./api/presetsigncircuits/getDocumentPresetSignCircuits/' + dItem.id));
	                        	docTemplSignCircuit.push({docId: dItem.id});
	                        }
	                    }
	                });
	                
	                $q.all(promises).then(function(data) {
	                	data = JSOG.decode(data);
	                	if(data) {
	                		for(var i = 0; i < promises.length; i++) {
	                			if(data[i] && data[i].data && data[i].data.id) {
	                				var doc = $linq(vm.documents).firstOrDefault(undefined, "x => x.id == " + data[i].data.id);
			                		if(doc && data[i].data.listPresetSignCircuits) {
			                			doc.listPresetSignCircuits = [];
			                			_.forEach(data[i].data.listPresetSignCircuits, function(psc) {
			                				psc.presetSignActions.sort(function( a, b ) {
				    	                        if (a.actionOrder < b.actionOrder){
				    	                            return -1;
				    	                        }
				    	                        if (a.actionOrder > b.actionOrder){
				    	                            return 1;
				    	                        }
				    	                        return 0;
				    	                    })
				    	                    doc.listPresetSignCircuits.push(psc);
			                			});
			                		}
	                			}
		                    }
	                	}
	                	
	                	loadPresetSignCircuits();
	                	
		                if(vm.presetCircuits.length == 0){
		                    vm.result.circuit.type = 'CUSTOM';
							vm.result.circuit.signatureWithoutOrder = false;
		                }
		                if(vm.isTemplatePresetCircuits){
		                	vm.result.circuit.selected = vm.presetCircuits[0];
		                }
                    }).catch(function(error) {});
	                
                }
            };
        }],
        bindings: {
            documentIds: '=?',
            documents: '=?',
            result: '=',
            dossierReceivers: '=?'
        }
    });